<template>
    <div id="DetailedProductionPlanning">
        <v-container fluid app>
            <v-card>
                <v-row no-gutters class="d-flex align-center justify-start mx-4 pt-2">
                    <v-col class="d-flex justify-start">
                        <div class="d-flex align-start mr-4">
                            <v-icon x-large color="primary" style="margin-top: 6px;">
                                {{ tableIcon }}
                            </v-icon>
                        </div>
                        <div class="d-flex flex-column align-start">
                            <span class="text-h6 font-weight-regular text-no-wrap">{{ tableName }}</span>
                            <div class="mb-2 d-flex align-center">
                                <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>
                                <span class="text-caption">{{ tableDescription }}</span>
                            </div>
                        </div>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" class="mb-2 mr-4 pa-0"
                                v-on=" on "
                                min-width="48px"
                                @click="getData()"
                                :disabled="select.length < 1 || loadingHeaders"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Atualizar</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mb-2 mr-4 pa-0"
                                @click="openSaveDialog()"
                                v-on="on"
                                min-width="48px"
                                color="primary"
                                :disabled="data.length < 1 || loadingEvents || disableSave"
                            >
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                        <span>Salvar</span>
                    </v-tooltip>

                    <v-dialog v-model="saveDialog" persistent max-width="800" max-height="450">
                        <v-card style="position: relative;">
                            <v-toolbar
                                dark
                                flat
                                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                                style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
                            >
                                <v-toolbar-title>Assistente de relatório dinâmico</v-toolbar-title>
                                <v-spacer />

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="transparent" class="mb-2 mt-2"
                                            v-on="on"
                                            min-width="48px"
                                            @click="closeSaveDialog()"
                                            depressed
                                        >
                                            <v-icon class="white--text">mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Fechar</span>
                                </v-tooltip>
                            </v-toolbar>
                            <v-stepper v-model="step" elevation="0">
                                <v-stepper-header>
                                    <v-stepper-step :complete="step > 1" step="1" >
                                        Descrição do relatório
                                    </v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step :complete="step > 2" step="2">
                                        Descrição do slide
                                    </v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="3">
                                        Permissões de grupo de usuários
                                    </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>

                                    <v-stepper-content step="1">
                                        <v-text-field
                                            ref="reportDescriptionInput"
                                            type="text"
                                            class="mx-2 mb-2"
                                            label="Descrição do relatório *"
                                            :hint="reportDescriptionHint"
                                            persistent-hint
                                            :value="report.description"
                                            @input=" v => report.description = v"
                                        ></v-text-field>
                                    </v-stepper-content>

                                    <v-stepper-content step="2">
                                        <div class="ml-2">
                                            <span class="text-caption">Descrição do relatório: </span>
                                            <span class="text-subtitle-1 font-weight-bold">{{ report.description }}</span>
                                        </div>
                                        <v-layout align-center justify-center wrap class="my-4">
                                            <v-flex xs12>
                                                <v-text-field
                                                    :value="report.number"
                                                    @input=" v => report.number = v"
                                                    max-length="11"
                                                    class="mx-2 my-1"
                                                    type="number"
                                                    label="Número sequencial do slide"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12>
                                                <v-text-field
                                                    :value="report.title"
                                                    @input=" v => report.title = v"
                                                    max-length="250"
                                                    class="mx-2 my-1"
                                                    label="Título do slide"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12>
                                                <v-text-field
                                                    :value="report.text"
                                                    @input=" v => report.text = v"
                                                    class="mx-2 my-1"
                                                    label="Texto estático do slide"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs12>
                                                <v-text-field
                                                    :value="report.format"
                                                    @input=" v => report.format = v"
                                                    class="mx-2 my-1"
                                                    label="Formato do slide"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        <v-divider class="mt-1 mb-1"></v-divider>
                                    </v-stepper-content>

                                    <v-stepper-content step="3">
                                        <v-layout align-center justify-center wrap>
                                            <v-flex xs12 class="mb-4">
                                                <p>Grupo de usuários para acesso</p>
                                            </v-flex>
                                            <v-flex xs12>
                                                <v-select
                                                    v-model="selectAdmin"
                                                    :items="admins"
                                                    class="my-4"
                                                    item-text="name"
                                                    item-value="id_user"                
                                                    label="Admin"
                                                    @change="changeSelectedAdmin($event)"
                                                    multiple
                                                    chips
                                                    hint="Selecione os usuários que podem visualizar e editar o relatório."
                                                    persistent-hint
                                                ></v-select>
                                            </v-flex>
                                            <v-flex xs12>
                                                <v-select
                                                    v-model="selectUser"
                                                    :items="users"
                                                    class="my-4"
                                                    item-text="name"
                                                    item-value="id_user"                
                                                    label="Usuários"
                                                    multiple
                                                    chips
                                                    hint="Selecione os usuários que podem apenas visualizar o relatório."
                                                    persistent-hint
                                                ></v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-divider class="mt-1 mb-2"></v-divider>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                            <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                                <v-divider class="mx-4"></v-divider>
                                <v-card-actions class="px-6 py-6">
                                    <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                                    <v-spacer></v-spacer>
                                    <v-btn text class="mr-4"
                                        color="primary"
                                        @click="step > 1 ? step-- : closeSaveDialog()"
                                        :ripple="false"
                                    >
                                        <span v-if="step === '1' || step === 1">Cancelar</span>
                                        <span v-else>Voltar</span>
                                    </v-btn>
                                    <v-btn color="primary"
                                        @click="step < '3' ? step++ : saveReport()"
                                        :disabled="disableSaveReportButton"
                                        :loading="savingReport"
                                    >
                                        <span v-if="step < 3"> Continuar </span>
                                        <span v-else> Salvar </span>
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-card>
                    </v-dialog>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mb-2 mr-4 pa-0"
                                @click="openDialog = true, getReports()"
                                v-on="on"
                                min-width="48px"
                                color="primary"
                            >
                                <v-icon>mdi-folder-open</v-icon>
                            </v-btn>
                        </template>
                        <span>Abrir relatório</span>
                    </v-tooltip>

                    <v-dialog v-model="openDialog" max-width="800" max-height="450">
                        <v-card style="position: relative;">
                            <v-toolbar
                                dark
                                flat
                                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                                style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
                            >
                                <v-toolbar-title>Abrir relatório</v-toolbar-title>
                                <v-spacer />

                                <v-tooltip bottom>
                                    <template v-slot:activator="on">
                                        <v-btn color="transparent" class="mb-2 mt-2"
                                            v-on="on"
                                            min-width="48px"
                                            @click="openDialog = false"
                                            depressed
                                        >
                                            <v-icon class="white--text">mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Fechar</span>
                                </v-tooltip>
                            </v-toolbar>
                            <v-container>
                                <v-layout wrap>
                                    <v-flex xs6>
                                        <v-select
                                            v-model="selectedReport"
                                            :items="reports"
                                            @input="getSlides(selectedReport)"
                                            clearable
                                            class="mx-2 my-4"
                                            item-text="dynamic_report"
                                            item-value="id_dynamic_report"                
                                            label="Relatório"
                                            hint="Selecione o relatório"
                                            persistent-hint
                                            :disabled="reports.length < 1"
                                        ></v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select
                                            v-model="selectedSlide"
                                            :items="slides"
                                            clearable
                                            class="mx-2 my-4"
                                            item-text="slide_title"
                                            item-value="id_dynamic_report_slide"               
                                            label="Slide"
                                            hint="Selecione um dos slides"
                                            persistent-hint
                                            :disabled="!selectedReport"
                                        ></v-select>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-divider class="mt-1 mb-1"></v-divider>
                            <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                                <v-card-actions class="px-6 py-6">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="openDialog = false">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="primary" @click="setSlide(), openDialog = false">
                                        Abrir
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-card>
                    </v-dialog>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mb-2 mr-4 pa-0"
                                @click="newDialog = true"
                                v-on="on"
                                min-width="48px"
                                color="primary"
                                :disabled="!selectedReport"
                            >
                                <v-icon>mdi-folder-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Salvar novo slide</span>
                    </v-tooltip>
                    
                    <v-dialog v-model="newDialog" max-width="800" max-height="450">
                        <v-card style="position: relative;">
                            <v-toolbar
                                dark
                                flat
                                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                                style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
                            >
                                <v-toolbar-title>Salvar novo slide</v-toolbar-title>
                                <v-spacer />

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="transparent" class="mb-2 mt-2"
                                            v-on="on"
                                            min-width="48px"
                                            @click="newDialog = false, step='1'"
                                            depressed
                                        >
                                            <v-icon class="white--text">mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Fechar</span>
                                </v-tooltip>
                            </v-toolbar>

                            <v-container>
                                <p class="subtitle-1 font-weight-regular">
                                    Descrição do relatório: {{ report.description }}
                                </p>
                                <v-layout align-center justify-center wrap class="my-4">
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="reportNewSlide.number"
                                            max-length="11"
                                            class="mx-2 my-1"
                                            label="Número sequencial do slide"
                                            :hint="`Este relatório já possui ${slides.length} slide(s)`"
                                            persistent-hint
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="reportNewSlide.title"
                                            max-length="250"
                                            class="mx-2 my-1"
                                            label="Título do slide"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="reportNewSlide.text"
                                            class="mx-2 my-1"
                                            label="Texto estático do slide"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="reportNewSlide.format"
                                            class="mx-2 my-1"
                                            label="Formato do slide"
                                        ></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-divider class="mt-1 mb-1"></v-divider>

                            <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                                <v-card-actions class="px-6 py-6">
                                    <v-spacer></v-spacer>
                                    <v-btn @click="newDialog = false" text class="primary--text">
                                        Cancelar
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="newSlide(report.id), newDialog = false"
                                        :disabled="reportNewSlide.number.length < 1 || reportNewSlide.title.length < 1 "
                                    >
                                        <span> Salvar </span>
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-card>
                    </v-dialog>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mb-2 mr-4 pa-0"
                                @click="copyDialog = true, getReports()"
                                v-on="on"
                                min-width="48px"
                                color="primary"
                                :disabled="!slide.id_dynamic_report_slide"
                            >
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>
                        <span>Copiar slide</span>
                    </v-tooltip>

                    <v-dialog v-model="copyDialog" max-width="800" max-height="450">
                        <v-card style="position: relative;">
                            <v-toolbar
                                dark
                                flat
                                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                                style="position: sticky; top: 0; width: 100%; z-index: 1000;" 
                            >
                                <v-toolbar-title>Copiar slide</v-toolbar-title>
                                <v-spacer />

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="transparent" class="mb-2 mt-2"
                                            v-on="on"
                                            min-width="48px"
                                            @click="copyDialog = false"
                                            depressed
                                        >
                                            <v-icon class="white--text">mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Fechar</span>
                                </v-tooltip>
                            </v-toolbar>
                            <v-container>
                                <v-layout wrap>
                                    <v-flex xs6>
                                        <v-select
                                            v-model="selectedReport"
                                            :items="reports"
                                            clearable
                                            class="mx-2 my-4"
                                            item-text="dynamic_report"
                                            item-value="id_dynamic_report"             
                                            label="Relatório"
                                            hint="Selecione para qual relatória irá o slide"
                                            persistent-hint
                                        ></v-select>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <v-divider class="mt-1 mb-1"></v-divider>
                            <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                                <v-card-actions class="px-6 py-6">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="copyDialog = false">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="primary" @click="copySlide(selectedReport), copyDialog = false">
                                        Copiar
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-card>
                    </v-dialog>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mb-2 mr-4 pa-0"
                                @click="reset()"
                                v-on="on"
                                min-width="48px"
                                color="primary"
                            >
                                <v-icon>mdi-file-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Gerar novo relatório</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" class="mb-2 mr-4 pa-0"
                                v-on="on"
                                min-width="48px"
                                @click="exportToExcel()"
                                :loading="loadingExport"
                            >
                                <v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar para Excel</span>
                    </v-tooltip>

                    <!-- <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" class="mb-2 mr-4 pa-0"
                                v-on="on"
                                @click="filterDialog = true"
                                min-width="48px"
                                :disabled=" columns.length < 1"
                            >
                                <v-icon>mdi-filter</v-icon>
                            </v-btn>
                        </template>
                        <span>Configuração de relatório</span>
                    </v-tooltip>

                    <v-dialog v-model="filterDialog" max-width="975" max-height="450">
                        <v-card>
                            <v-toolbar
                                dark
                                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                                style="position: sticky; top: 0; width: 100%; z-index: 1000;"
                            >
                                <v-toolbar-title>Filtro</v-toolbar-title>
                                <v-spacer />

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn color="transparent" class="mb-2 mr-4 mt-2"
                                            v-on="{ ...tooltip}"
                                            min-width="48px"
                                            @click="getData(), filterDialog = false"
                                            depressed
                                        >
                                            <v-icon class="white--text">mdi-filter</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Filtrar</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn color="transparent" class="mb-2 mt-2"
                                            v-on="{ ...tooltip}"
                                            min-width="48px"
                                            @click="filterDialog = false"
                                            depressed
                                        >
                                            <v-icon class="white--text">mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Fechar</span>
                                </v-tooltip>
                            </v-toolbar>

                            <v-container fluid fill-height>
                                <v-layout wrap>
                                    <v-flex v-for="column in columns" :key="column.index" xs3>
                                        <v-checkbox
                                            v-model="column.visible"
                                            :label="column.text"
                                            class="mx-2"
                                        />
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card>
                    </v-dialog> -->

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" class="mb-2 pa-0"
                                v-on="on"
                                min-width="48px"
                                @click="$router.push('/')"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>

                </v-row>

                <v-divider class="mt-1 mb-2"></v-divider>

                <v-row no-gutters class="d-flex justify-start align-center mb-3" style="height: 24px !important; width: 100%;">
                    <v-col class="d-flex justify-start" style="height: 24px !important;">
                        <v-tabs  
                            v-model="tab"
                            height="24px"
                        >
                            <v-tabs-slider color="primary"></v-tabs-slider>

                            <v-tab :disabled="select.length < 1">
                                <span class="text-caption text-capitalize"> Evento</span>
                            </v-tab>
                            <v-tab :disabled="select.length < 1">
                                <span class="text-caption text-capitalize"> Filtros</span>
                            </v-tab>
                            <v-tab :disabled="select.length < 1">
                                <span class="text-caption text-capitalize"> Exibir</span>
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-layout class="mx-4">
                            <v-flex xs6>
                                <v-select
                                    v-model="select"
                                    :items="eventItems"
                                    height="42.5"
                                    class="mx-2 mt-0 mb-4"
                                    item-text="text"
                                    item-value="id"                
                                    label="Eventos"
                                    multiple
                                    chips
                                    hint="Selecione os Eventos para comparar"
                                    :disabled="filters.length < 1"
                                    :loading="filters.length < 1"
                                    persistent-hint
                                ></v-select>
                                <!-- <v-layout v-if="operation != 'Nenhuma'" wrap>
                                    <v-flex xs6>
                                        <v-select
                                            v-model="selectFirst"
                                            :items="items"
                                            clearable
                                            class="mx-2 my-4"
                                            item-text="text"
                                            item-value="id"                
                                            label="Primeiro evento"
                                            hint="Selecione um primeiro evento"
                                            persistent-hint
                                        ></v-select>
                                    </v-flex>
                                    <v-flex xs6>
                                        <v-select
                                            v-model="selectSecond"
                                            :items="items"
                                            clearable
                                            class="mx-2 my-4"
                                            item-text="text"
                                            item-value="id"                
                                            label="Segundo evento"
                                            hint="Selecione um segundo evento para comparar"
                                            persistent-hint
                                        ></v-select>
                                    </v-flex>
                                </v-layout> -->
                            </v-flex>
                            <v-flex xs3>
                                <v-radio-group v-model="option" row class="mx-6 mt-4">
                                    <!-- <v-radio
                                        label="Padrão"
                                        value="BI_YEAR"
                                    ></v-radio> -->
                                    <v-radio
                                        label="Ano"
                                        value="YEAR"
                                    ></v-radio>
                                    <v-radio
                                        label="Mês"
                                        value="YEAR_MONTH"
                                    ></v-radio>
                                </v-radio-group>
                            </v-flex>
                        </v-layout>
                    </v-tab-item>
                    <v-tab-item>
                        <v-layout wrap mx-4 mt-2>
                            <v-flex v-for="column in filters" :key="column.column_id" xs2>
                                <v-autocomplete
                                    v-model="column.selectedFilters"
                                    :items="column.items"
                                    :hide-no-data="true"
                                    clearable
                                    class="mx-2 my-n2"
                                    item-text="text"
                                    item-value="id"                
                                    :label="column.text"
                                    @click="showFilterItems(column)"
                                    :loading="filterIsLoading(column)"
                                    @input="searchFilters = {}"
                                    :search-input.sync="searchFilters[column.name]"
                                    multiple
                                />
                            </v-flex>
                        </v-layout>
                    </v-tab-item>
                    <v-tab-item>
                        <v-container fluid fill-height>
                            <v-layout wrap>
                                <v-flex v-for="column in selects" :key="column.index" xs2>
                                    <v-checkbox
                                        v-model="column.visible"
                                        :label="column.text"
                                        class="mx-2 my-n2"
                                    />
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>

                <!-- <v-btn class="my-4" elevation="2" @click="getData()">Refresh</v-btn> -->

                <v-divider class="mt-1 mb-4"></v-divider>

                <v-container fluid fill-height transition="fade-transition">
                    <v-layout align-center justify-center>
                        <span class="h1" v-if="data.length < 1 && !loadingEvents">
                            Selecione um ou mais eventos para gerar a tabela
                        </span>
                        <v-progress-circular
                            v-if="loadingEvents"
                            width="10"
                            size="100"
                            color="primary"
                            indeterminate
                            >
                        </v-progress-circular>
                    </v-layout>
                </v-container>
                <p v-if="data.length > 1 && !loadingEvents" class="mt-n4" style="position: absolute;">
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                            <span v-on="on" class="ml-2">
                                <v-icon class="primary--text">
                                    mdi-chart-tree
                                </v-icon>
                                Visualização
                            </span>
                        </template>
                        <span class="caption">
                            Escolha o formato de exibição do relatório
                        </span>
                    </v-tooltip>
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                            <span v-on="on" style="margin-left: 190px;">
                                <v-icon class="primary--text">
                                    mdi-import
                                </v-icon>
                                Campos gerenciais
                            </span>
                        </template>
                        <span class="caption">
                            Arraste os campos para adicionar ao relatório
                        </span>
                    </v-tooltip>
                </p>
                <p v-if="data.length > 1 && !loadingEvents" style="margin-top: 155px; position: absolute;">
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                            <span v-on="on" class="ml-2">
                                <v-icon class="primary--text">
                                    mdi-sigma
                                </v-icon>
                                Valores
                            </span>
                        </template>
                        <span class="caption">
                            Escolha o formato de exibição dos valores no relatório
                        </span>
                    </v-tooltip>
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                            <span v-on="on" style="margin-left: 220px;">
                                <v-icon class="primary--text">
                                    mdi-table-column
                                </v-icon>
                                Colunas
                            </span>
                        </template>
                        <span class="caption">
                            Coloque os campos a serem visualizados em colunas no relatório
                        </span>
                    </v-tooltip>
                    <br>
                    <br>
                    <span class="grey--text text--darken-2 caption ml-8">
                        Ordenar linhas e colunas:
                    </span>
                </p>
                <p v-if="data.length > 1 && !loadingEvents" :style="labelClass[0]">
                    <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                            <span v-on="on" class="ml-2">
                                <v-icon class="primary--text">
                                    mdi-table-row
                                </v-icon>
                                Linhas
                            </span>
                        </template>
                        <span class="caption">
                            Coloque os campos a serem visualizados em linhas no relatório
                        </span>
                    </v-tooltip>
                    <v-layout class="mt-n6" style="margin-left:300px;">
                        <v-flex>
                            <span class="ml-5">
                                <v-icon class="primary--text">
                                    mdi-table-large
                                </v-icon>
                                Relatório
                            </span>
                        </v-flex>
                        <v-flex>
                            <v-checkbox
                                v-model="showTotalRow"
                                label="Mostrar total das linhas"
                                class="mt-n1 ml-4"
                            />
                        </v-flex>
                        <v-flex>
                            <v-checkbox
                                v-model="showTotalCol"
                                label="Mostrar total das colunas"
                                class="mt-n1 ml-4"
                            />
                        </v-flex>
                        <v-flex>
                            <v-btn class="mr-2 ml-4 mt-n1" dark color="primary" @click="dialog = true">
                                <v-icon left class="mr-2">mdi-magnify</v-icon>
                                Preview
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </p>

                
                <v-icon v-if="data.length > 1 && !loadingEvents" style="margin-left: 225px; margin-top: 45px; position: absolute;">
                    mdi-menu-down
                </v-icon>
                <v-icon v-if="data.length > 1 && !loadingEvents" style="margin-left: 225px; margin-top: 260px; position: absolute;">
                    mdi-menu-down
                </v-icon>
                <v-icon v-if="data.length > 1 && !loadingEvents && labelClass[1]" style="margin-left: 225px; margin-top: 305px; position: absolute;">
                    mdi-menu-down
                </v-icon>
                <v-icon v-if="data.length > 1 && !loadingEvents && labelClass[2]" style="margin-left: 225px; margin-top: 345px; position: absolute;">
                    mdi-menu-down
                </v-icon>

                <vue-pivottable-ui
                    v-if="!loadingEvents"
                    v-model="config"
                    :data="data"
                    :rowTotal="showTotalRow"
                    :colTotal="showTotalCol"
                    :localeStrings="localeStrings[locale]"
                    :rendererName="rendererName"
                    :aggregatorName="aggregatorName"
                    :tableColorScaleGenerator="colorScaleGenerator"
                    :attributes="attributes"
                    :sorters="sorters"
                    :rows="rows"
                    :cols="cols"
                    :vals="vals"
                    :disabledFromDragDrop="disabledFromDragDrop"
                    :sortonlyFromDragDrop="sortonlyFromDragDrop"
                    :hiddenFromDragDrop="hiddenFromDragDrop"
                    :hiddenFromAggregators="hiddenFromAggregators"
                    rowOrder="key_a_to_z"
                    class="mt-n4"
                >
                    <colgroup slot="colGroup">
                        <col :width="300" />
                        <col />
                    </colgroup>
                    <div v-if="loading" slot="output">loading...</div>
                    <template
                        v-if="!loading"
                        slot="output"
                        slot-scope="{ pivotData }"
                    >
                        {{ pivotData }}
                    </template>
                    <template slot="pvtAttr" slot-scope="{ name }">
                        {{ name }}
                    </template>
                </vue-pivottable-ui>
                       
                <report-preview
                    :dialog="dialog"
                    :renderer="rendererName"
                    :aggregator="aggregatorName"
                    :vals="vals"
                    :headers="columns"
                    :filterColsList="filters"
                    :events="select"
                    :optionPreview="option"
                    :colsFields="cols"
                    :rowsFields="rows"
                    :slidesList="slides"
                    :showTotal="{col: showTotalCol, row: showTotalRow}"
                >
                </report-preview>

            </v-card>
        </v-container>
    </div>
</template>

<script>
// import { VuePivottableUi, VuePivottable, PivotUtilities, Renderer } from "vue-pivottable"
import { mapGetters } from 'vuex'
import { VuePivottableUi, PivotUtilities, Renderer } from "../../lib/vue-pivottable/src"
import { scaleLinear } from "d3-scale"
import ReportPreview from '../components/znap/ReportPreview'

export default {
    name: "DetailedProductionPlanning",

    components: { VuePivottableUi, ReportPreview },

    computed: {
        ...mapGetters('auth', ['getUser']),

        endpoint() {
            return [this.$ipProductionPlanning, "production-planning"]
        },

        endpointDynamicReport() {
            return [this.$ipDynamicReport, "dynamic-report"]
        },

        endpointSaveReport() {
            return [this.$ipDynamicReport, "dynamic-report-slide"]
        },

        endpointUser() {
            return [this.$ipUser, "user"]
        },

        endpointSecurity() {
            return [this.$ipSecurity, "user-group"]
        },

        unusedAttrs() {
            return this.config.unusedAttrs
        },

        aggregators() {
            const usFmt = PivotUtilities.numberFormat()
            // const usFmtInt = PivotUtilities.numberFormat({ digitsAfterDecimal: 0 })
            // const usFmtPct = PivotUtilities.numberFormat({
            //   digitsAfterDecimal: 1,
            //   scaler: 100,
            //   suffix: '%'
            // })

            return ((tpl) => ({
                // 'Count': tpl.count(usFmtInt),
                // 'Count Unique Values': tpl.countUnique(usFmtInt),
                // 'List Unique Values': tpl.listUnique(', '),
                Sum: tpl.sum(usFmt),
                // 'Integer Sum': tpl.sum(usFmtInt),
                // 'Average': tpl.average(usFmt),
                // 'Median': tpl.median(usFmt),
                // 'Sample Variance': tpl.var(1, usFmt),
                // 'Sample Standard Deviation': tpl.stdev(1, usFmt),
                // 'Minimum': tpl.min(usFmt),
                // 'Maximum': tpl.max(usFmt),
                // 'First': tpl.first(usFmt),
                // 'Last': tpl.last(usFmt),
                // 'Sum over Sum': tpl.sumOverSum(usFmt),
                // 'Sum as Fraction of Total': tpl.fractionOf(tpl.sum(), 'total', usFmtPct),
                // 'Sum as Fraction of Rows': tpl.fractionOf(tpl.sum(), 'row', usFmtPct),
                // 'Sum as Fraction of Columns': tpl.fractionOf(tpl.sum(), 'col', usFmtPct),
                // 'Count as Fraction of Total': tpl.fractionOf(tpl.count(), 'total', usFmtPct),
                // 'Count as Fraction of Rows': tpl.fractionOf(tpl.count(), 'row', usFmtPct),
                // 'Count as Fraction of Columns': tpl.fractionOf(tpl.count(), 'col', usFmtPct)
            }))(PivotUtilities.aggregatorTemplates)
        },

        sorters () {
            // const sortAs = PivotUtilities.sortAs(this.accountGroups)
            let orderedArray = [...this.accountGroups]
            return {
                "Conta gerencial": PivotUtilities.sortAs([...orderedArray]),
                "Ano/mês": PivotUtilities.sortAs([...this.yearMonths]),
                "Período (mês)": PivotUtilities.sortAs([...this.periodMonths])
            }
        },

        renderers() {
            const TableRenderer = Renderer.TableRenderer
            // const PlotlyRenderer = Renderer.PlotlyRenderer
            return (() => ({
                Table: TableRenderer.Table,
                "Table Heatmap": TableRenderer["Table Heatmap"],
                "Table Col Heatmap": TableRenderer["Table Col Heatmap"],
                "Table Row Heatmap": TableRenderer["Table Row Heatmap"],
                "Export Table TSV": TableRenderer["Export Table TSV"],
                // 'Grouped Column Chart': PlotlyRenderer['Grouped Column Chart'],
                // 'Stacked Column Chart': PlotlyRenderer['Stacked Column Chart'],
                // 'Grouped Bar Chart': PlotlyRenderer['Grouped Bar Chart'],
                // 'Stacked Bar Chart': PlotlyRenderer['Stacked Bar Chart'],
                // 'Line Chart': PlotlyRenderer['Line Chart'],
                // 'Dot Chart': PlotlyRenderer['Dot Chart'],
                // 'Area Chart': PlotlyRenderer['Area Chart'],
                // 'Scatter Chart': PlotlyRenderer['Scatter Chart'],
                // 'Multiple Pie Chart': PlotlyRenderer['Multiple Pie Chart']
            }))()
        },

        valUnused() {
            return document.getElementsByClassName("pvtUnused")[0].offsetHeight
        },

        reportDescriptionHint() {
            let hint = ''

            if (this.verifiedReportDescription !== 0 && this.report.description.length > 0) {
                if (this.verifiedReportDescription === -1) {
                    hint = "Está descrição já está em uso."
                } else {
                    hint = "Tudo certo!"
                }
            } else {
                hint = ''
            }

            return hint
        },
        

        disableSaveReportButton() {
            let disabled = false

            if ((this.step === '1' || this.step === 1) && (!this.report.description.length || this.verifiedReportDescription !== 1)) {
                disabled = true
            }

            if ((this.step === '2' || this.step === 2) && (!this.report.number || !this.report.title)) {
                disabled = true
            }

            return disabled
        },

        eventItems() {
            let eventItems
            let eventOptions = this.items

            if (this.select.length) {
                let selectedEvent = eventOptions.find(i => i.id === this.select[0])
                eventItems = eventOptions.filter(e => e.id_account_group_version === selectedEvent.id_account_group_version)
            } else {
                eventItems = eventOptions
            }
            
            return eventItems
        }
    },

    data: () => ({
        tableName: "Construtor de relatórios dinâmicos",
        tableIcon: "mdi-table-large",
        tableDescription: "Forneça uma descrição para esta tabela.",
        tableRows: 100000,

        noDataMessage: "",
        noData: true,

        select: [],
        selectFirst: null,
        selectSecond: null,
        items: [],
        id_account_group_version: 0,

        columns: [],
        columnsBiYear: [],
        columnsYear: [],
        columnsYearMonth: [],

        loadingEvents: false,
        loadingVals: false,
        loadingExport: false,
        dialog: false,
        labelClass: [
            'position: absolute; margin-top: 375px;',
            true,
            false
        ],

        saveDialog: false,
        openDialog: false,
        copyDialog: false,
        newDialog: false,

        selectedReport: null,
        selectedSlide: null,

        zoom: false,

        headers: [
            {
                text: 'Nome', value: 'nome' 
            }
        ],
        reports: [],
        slides: [],

        option: "YEAR",
        operator: null,
        operation: "Nenhuma",
        operations: ["- (Diferença)", "% (Percentual)", "Nenhuma"],
        filterDialog: false,
        valueFilter: {},

        selectedView: '',
        selectedVals: ['', ''],
        selectedCols: [],
        selectedRows: [],

        users: [],
        admins: [],
        selectAdmin: [],
        selectUser: [],
        selectAdminUnchanged: [],
        selectUserUnchanged: [],
        adminUserGroup: null,
        userGroup: null,
        check: true,

        step: "1",
        page: "0",
        slideTime: "5000",
        time: [
            {
                text: "Estático",
                time: "0"
            },
            {
                text: "5 segundos",
                time: "5000"
            },
            {
                text: "15 segundos",
                time: "15000"
            },
            {
                text: "30 segundos",
                time: "30000"
            },
        ],

        descriptionCompare: "",

        report: {
            id: null,
            description: "",
            number: null,
            title: null,
            text: null,
            format: null,
            pivotTable: {
                option: null,
                operation: null,
                renderer: null,
                aggregator: null,
                vals: [],
                headers: [],
                cols: [],
                rows: [],
                filters: []
            }
        },

        reportNewSlide: {
            number: '',
            title: '',
            text: null,
            format: null,
            pivotTable: {
                option: null,
                operation: null,
                renderer: null,
                aggregator: null,
                vals: [],
                headers: [],
                cols: [],
                rows: [],
                filters: []
            }
        },

        slide: {},
        reportOpen: {},

        config: {},
        filteredData: [],
        showTotalRow: true,
        showTotalCol: true,
        data: [],
        dataRows: [],
        attributes: [],
        rows: ["Conta gerencial"],
        cols: ["Nome do evento"],
        vals: ["Total Ano"],
        valsHeight: null,
        disabledFromDragDrop: [],
        hiddenFromDragDrop: [],
        hiddenFromAggregators: [],
        sortonlyFromDragDrop: [],
        pivotColumns: [],
        loading: false,
        aggregatorName: "Sum",
        rendererName: "Table",
        dataSourceSettings: {  
            showGrandTotals: false,
            showSubTotals: false,
        },
        loadingHeaders: false,
        accountGroups: [],
        yearMonths: [
            'Jan/20', 'Fev/20', 'Mar/20', 'Abr/20', 'Mai/20', 'Jun/20', 'Jul/20', 'Ago/20', 'Set/20', 'Out/20', 'Nov/20', 'Dev/20',
            'Jan/21', 'Fev/21', 'Mar/21', 'Abr/21', 'Mai/21', 'Jun/21', 'Jul/21', 'Ago/21', 'Set/21', 'Out/21', 'Nov/21', 'Dev/21',
            'Jan/22', 'Fev/22', 'Mar/22', 'Abr/22', 'Mai/22', 'Jun/22', 'Jul/22', 'Ago/22', 'Set/22', 'Out/22', 'Nov/22', 'Dev/22',
            'Jan/23', 'Fev/23', 'Mar/23', 'Abr/23', 'Mai/23', 'Jun/23', 'Jul/23', 'Ago/23', 'Set/23', 'Out/23', 'Nov/23', 'Dev/23'
        ],
        periodMonths: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],

        //save reports update
        verifiedReportDescription: 0,
        savingReport: false,

        //tabs refactor
        tab: 0,
        filters: [],
        loadingFilters: [],
        selects: [],

        searchFilters: {},

        disableSave: false,

        localeStrings: {
            en: {
                renderError:
                    "An error occurred rendering the PivotTable results.",
                computeError:
                    "An error occurred computing the PivotTable results.",
                uiRenderError: "An error occurred rendering the PivotTable UI.",
                selectAll: "Select All",
                selectNone: "Select None",
                tooMany: "too many values to show",
                filterResults: "Filter values",
                totals: "Totals",
                only: "only",
                rendererNames: {
                    Table: "Table",
                    "Table Heatmap": "Table Heatmap",
                    "Table Col Heatmap": "Table Col Heatmap",
                    "Table Row Heatmap": "Table Row Heatmap",
                    "Export Table TSV": "Export Table TSV",
                    "Grouped Column Chart": "Grouped Column Chart",
                    "Stacked Column Chart": "Stacked Column Chart",
                    "Grouped Bar Chart": "Grouped Bar Chart",
                    "Stacked Bar Chart": "Stacked Bar Chart",
                    "Line Chart": "Line Chart",
                    "Dot Chart": "Dot Chart",
                    "Area Chart": "Area Chart",
                    "Scatter Chart": "Scatter Chart",
                    "Multiple Pie Chart": "Multiple Pie Chart",
                },
                aggregatorMap: {
                    Count: "Count",
                    "Count Unique Values": "Count Unique Values",
                    "List Unique Values": "List Unique Values",
                    Sum: "Sum",
                    "Integer Sum": "Integer Sum",
                    Average: "Average",
                    Median: "Median",
                    "Sample Variance": "Sample Variance",
                    "Sample Standard Deviation": "Sample Standard Deviation",
                    Minimum: "Minimum",
                    Maximum: "Maximum",
                    First: "First",
                    Last: "Last",
                    "Sum over Sum": "Sum over Sum",
                    "Sum as Fraction of Total": "Sum as Fraction of Total",
                    "Sum as Fraction of Rows": "Sum as Fraction of Rows",
                    "Sum as Fraction of Columns": "Sum as Fraction of Columns",
                    "Count as Fraction of Total": "Count as Fraction of Total",
                    "Count as Fraction of Rows": "Count as Fraction of Rows",
                    "Count as Fraction of Columns":
                        "Count as Fraction of Columns",
                },
            },
            pt: {
                renderError:
                    "Ocorreu um erro ao renderizar os resultados da tabela dinâmica.",
                computeError:
                    "Ocorreu um erro ao calcular os resultados da tabela dinâmica.",
                uiRenderError: "Ocorreu um erro ao renderizar a Interface de Uso da tabela dinâmica.",
                selectAll: "Selecionar tudo",
                selectNone: "Selecione Nenhum",
                tooMany: "muitos valores para mostrar",
                filterResults: "Filtrar valores",
                totals: "Totais",
                only: "apenas",
                rendererMap: {
                    Table: 'Tabela',
                    'Table Heatmap': 'Mapa de calor da tabela',
                    'Table Col Heatmap': 'Mapa de calor de coluna da tabela',
                    'Table Row Heatmap': 'Mapa de calor de linha da tabela',
                    'Export Table TSV': 'Copiar para Excel',
                    'Grouped Column Chart': 'Gráfico de colunas agrupadas',
                    'Stacked Column Chart': 'Gráfico de colunas empilhadas',
                    'Grouped Bar Chart': 'Gráfico de Barras Agrupadas',
                    'Stacked Bar Chart': 'Gráfico de Barras Empilhadas',
                    'Line Chart': 'Gráfico de linhas',
                    'Dot Chart': 'Gráfico de pontos',
                    'Area Chart': 'Gráfico de área',
                    'Scatter Chart': 'Gráfico de dispersão',
                    'Multiple Pie Chart': 'Gráfico de pizza múltipla',
                },
                aggregatorMap: {
                    Count: "Contagem",
                    "Count Unique Values": "Contar valores exclusivos",
                    "List Unique Values": "Listar valores únicos",
                    Sum: "Soma",
                    "Integer Sum": "Soma Inteira",
                    Average: "Média",
                    Median: "Mediana",
                    "Sample Variance": "Variância da amostra",
                    "Sample Standard Deviation": "Desvio padrão da amostra",
                    Minimum: "Mínimo",
                    Maximum: "Máximo",
                    First: "Primeiro",
                    Last: "Último",
                    "Sum over Sum": "Soma sobre Soma",
                    "Sum as Fraction of Total": "Soma como fração do total",
                    "Sum as Fraction of Rows": "Soma como fração de linhas",
                    "Sum as Fraction of Columns": "Soma como fração de colunas",
                    "Count as Fraction of Total": "Contar como fração do total",
                    "Count as Fraction of Rows": "Contar como fração de linhas",
                    "Count as Fraction of Columns":
                        "Contar como fração de colunas",
                }
            },
                ko: {
                renderError: '피벗 테이블 결과를 렌더링하는 동안 오류가 발생 했습니다.',
                computeError: '피벗 테이블 결과를 계산하는 동안 오류가 발생 했습니다.',
                uiRenderError: '피벗 테이블 UI를 렌더링하는 동안 오류가 발생 했습니다.',
                selectAll: '모두 선택',
                selectNone: '선택 안함',
                tooMany: '표시 할 값이 너무 많습니다.',
                filterResults: '값 필터링',
                totals: '합계',
                only: '단독',
                rendererMap: {
                    Table: '테이블',
                    'Table Heatmap': '테이블 히트맵',
                    'Table Col Heatmap': '테이블 열 히트맵',
                    'Table Row Heatmap': '테이블 행 히트맵',
                    'Export Table TSV': '테이블 TSV로 내보내기',
                    'Grouped Column Chart': '그룹화된 차트',
                    'Stacked Column Chart': '누적 차트',
                    'Grouped Bar Chart': '그룹화된 막대형 차트',
                    'Stacked Bar Chart': '누적 막대형 차트',
                    'Line Chart': '라인 차트',
                    'Dot Chart': '도트 차트',
                    'Area Chart': '영역 차트',
                    'Scatter Chart': '분산형 차트',
                    'Multiple Pie Chart': '다중 원형 차트'
                },
                aggregatorMap: {
                    Count: '개수',
                    'Count Unique Values': '고유 값 개수',
                    'List Unique Values': '고유 값 목록',
                    Sum: '합계',
                    'Integer Sum': '정수 합계',
                    Average: '평균',
                    Median: '중앙',
                    'Sample Variance': '표본 분산',
                    'Sample Standard Deviation': '샘플 표준 편차',
                    Minimum: '최소',
                    Maximum: '최대',
                    First: '첫 번째',
                    Last: '마지막',
                    'Sum over Sum': '누적 합계',
                    'Sum as Fraction of Total': '부분별 비율 합계',
                    'Sum as Fraction of Rows': '행별 비율 합계',
                    'Sum as Fraction of Columns': '열별 비율 합계',
                    'Count as Fraction of Total': '전체 중 부분 개수',
                    'Count as Fraction of Rows': '행 부분 개수',
                    'Count as Fraction of Columns': '열 부분 개수'
                }
            }
        },
        //getsystem.language
        // Count, Count as Fraction of * : 1 select, height: 126
        // Sum over Sum : 3 selects, height: 212
        // Demais: 2 selects, height: 169
        locale: "pt",
    }),

    created () {
        this.getEvents()
        this.callHeaders()
        // this.getUsers()
        
        this.$root.$on('closeDialog', () => {
            this.dialog = false
        })
        this.$root.$on('getView', ($event) => {
            this.selectedView = $event
            this.rendererName = this.selectedView
        })
        this.$root.$on('changeVal', ($event) => {
            this.aggregatorName = $event
            // this.selectedVals[0] = ($event)
            if (
                $event === 'Count' ||
                $event === 'Count as Fraction of Total'||
                $event === 'Count as Fraction of Rows' ||
                $event === 'Count as Fraction of Columns'
            ) {
                this.labelClass = [
                    'position: absolute; margin-top: 330px;',
                    false,
                    false
                ]
            }
            else if ($event === 'Sum over Sum') {
                this.labelClass = [
                    'position: absolute; margin-top: 415px;',
                    true,
                    true
                ]
            }
            else {
                this.labelClass = [
                    'position: absolute; margin-top: 375px;',
                    true,
                    false
                ]
            }
        })

        this.$root.$on('getVals', ($event) => {
            $event[0] === 0 ? this.selectedVals[0] = $event[1] : this.selectedVals[1] = $event[1]
            this.vals = this.selectedVals
        })

        this.$root.$on('getCol', ($event) => {
            if ($event[1] === 1) {
                let val = []
                this.selectedCols.forEach((col) => {
                    if (col != $event[0]) {
                        val.push(col)
                    }
                })
                this.selectedCols = val
            } else {
                this.selectedCols.push($event[0])
            }
            // alert(this.selectedCols)
        })
        this.$root.$on('getRow', ($event) => {
            if ($event[1] === 1) {
                let val = []
                this.selectedRows.forEach((col) => {
                    if (col != $event[0]) {
                        val.push(col)
                    }
                })
                this.selectedRows = val
            } else {
                this.selectedRows.push($event[0])
            }
            // alert(this.selectedRows)
        })

        this.$root.$on('getValueFilter', ($event) => {
            this.valueFilter = $event
        })

        // Esse evento deve ser emitido ao carregar um relatório já salvo
        // this.$root.$emit('setValueFilter', this.valueFilter)
    },

    mounted () {
        if(this.$route.query.report) {
            this.loadSlideToEdit()
        }
    },

    methods: {
        colorScaleGenerator(values) {
            const scale = scaleLinear()
                .domain([0, Math.max.apply(null, values)])
                .range(["#fff", "#88f"])
            return (x) => {
                return { "background-color": scale(x) }
            }
        },

        
        reset () {
            Object.assign(this.$data, this.$options.data());
            this.slides = []
            this.getEvents()
            this.callHeaders()
            // this.getUsers()
            this.getReports()
            this.disableSave = false
        },

        async callHeaders() {
            // await this.getHeaders('BI_YEAR')
            await this.getHeaders('YEAR')
            await this.getHeaders('YEAR_MONTH')
            this.columns = this.columnsYear
        },

        async getHeaders(option) {
            try {
                this.loadingHeaders = true
                const payload = {
                    queryType: option,
                    showCommonHeaders: true
                }
                
                let res = await this.$http.post(
                    this.endpoint[0] + this.endpoint[1] + "/analyse-headers",
                    payload
                )
                if (res) {
                    /* res.data.headers.forEach((column) => {
                        let item = {}
                            if (column.order) {
                                item = {
                                    name: column.name,
                                    visible: column.visible,
                                    order: column.order
                                }
                            } else {
                                item = {
                                    name: column.name,
                                    visible: column.visible,
                                }
                            }
                            this.columns.push(item)
                    }) */
                    // if (option === 'BI_YEAR') {
                    //     this.columnsBiYear = res.data.headers
                    // }
                    if (option === 'YEAR') {
                        this.columnsYear = res.data.headers
                    }
                    if (option === 'YEAR_MONTH') {
                        this.columnsYearMonth = res.data.headers
                    }
                    this.loadingHeaders = false
                    // this.option = option
                    // this.columns = res.data.headers
                }
            } catch (err) {
                this.loadingHeaders = false
                this.$fnError(err)
            }
        },

        async getData() {
            this.loadingEvents = true
            this.loadingVals = true

            const filterConditions = []
            this.filters.forEach((filter) => {
                if (filter.selectedFilters.length > 0) {
                    let values = []
                    filter.selectedFilters.forEach((selected) => {
                        values.push(selected)
                    })
                    filterConditions.push({
                        AndOr: "AND",
                        column: filter.columnId,
                        operator: "IN",
                        value: values
                    })
                }
            })

            if (this.selectFirst) {
                this.select = this.selectSecond != null ? this.select = [this.selectFirst, this.selectSecond]
                                                : this.select = [this.selectFirst]
            }
            try {
                const payload = {
                    id_account_group_version: this.id_account_group_version,
                    events: [...this.select],
                    NoFormulas: true,
                    columns: this.columns,
                    calculateDifference: this.operator,
                    ResultInArray: true,
                    queryType: this.option,
                    filter: {
                        page: 0,
                        tableRows: 100000,
                        conditions: filterConditions,
                        having: [],
                    },
                }

                let res = await this.$http.post(
                    this.endpoint[0] + this.endpoint[1] + "/analyse",
                    payload
                )
                if (res) {
                    this.dataRows = res.data.rows
                    this.data = this.dataRows
                    this.pivotColumns = this.columns
                    this.disabledFromDragDrop = []
                    this.hiddenFromDragDrop = []
                    this.attributes = []

                    this.columns.forEach((column) => {
                        if (column.order === undefined || column.visible === false) {
                            this.hiddenFromDragDrop.push(column.text)
                        } else {
                            this.attributes.push(column.text)
                        }
                        if (!column.valueColumn) {
                            this.hiddenFromAggregators.push(column.text)
                        }
                    })
                    this.loadingEvents = false
                }
            } catch (err) {
                this.loadingEvents = false
                this.loadingVals = false
                this.$fnError(err)
            }
        },

        async getEvents() {
            try {
                const payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: 'AND',
                                column: 'id_module',
                                operator: '=',
                                value: 7
                            }
                        ]
                    }
                }
                let res = await this.$http.post(
                    this.$ipEvent + "event" + "/list-options",
                    payload
                )
                if (res) {
                    this.items = res.data.rows
                    if(res.data.rows.length > 0) this.id_account_group_version = res.data.rows[0].id_account_group_version
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async saveDescription() {
            if (!this.selectedReport) {
                try {
                    const payload = {
                        dynamic_report: this.report.description,
                        report_type: '0'
                    }
                    
                    let res = await this.$http.post(
                        this.endpointDynamicReport[0] + this.endpointDynamicReport[1] + "/create",
                        payload
                    )
                    if (res) {
                        this.report.id = res.data.id[0]
                        this.selectedReport = this.report.id
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            } else {
                if (this.report.description !== this.descriptionCompare) {
                    try {
                        const payload = {
                            dynamic_report: this.report.description,
                            report_type: '0'
                        }
                        
                        await this.$http.put(
                            this.endpointDynamicReport[0] + this.endpointDynamicReport[1] + "/edit/" + this.selectedReport,
                            payload
                        )
                        this.report.id = this.selectedReport
                    } catch (err) {
                        this.$fnError(err)
                    }
                } else {
                    this.report.id = this.selectedReport
                }
            }
        },

        openSaveDialog() {
            !this.selectedReport ? this.report.number = 1 : false
            this.saveDialog = true
            this.getUsers()
        },

        closeSaveDialog() {
            this.savingReport = false
            this.saveDialog = false
            this.step = '1'
            //para impedir que o close limpe caso já tenha report aberto
            if (!this.report.id){
                this.selectAdmin = []
                this.selectUser = []
                this.admins = []
                this.users = []
                this.report = {
                    id: null,
                    description: "",
                    number: null,
                    title: null,
                    text: null,
                    format: null,
                    pivotTable: {}
                }
                this.verifiedReportDescription = 0
            }
        },

        async getUsers() {
            try {
                const res = await this.$http.post(this.$ipUser + "user/list", {})
                if (res) {
                    let users = res.data.rows.sort((a, b) => {
                        let nameA = a.name.toLowerCase(),
                            nameB = b.name.toLowerCase()

                        if (nameA < nameB) return -1
                        if (nameA > nameB) return 1
                        return 0 
                    })

                    if (!this.selectedReport || !this.$route.query.report) {
                        const currentUser = users.find(u => u.id_user === this.getUser.id_user)
                        this.users = users.filter(u => u.id_user !== currentUser.id_user)
                        this.selectAdmin.push(currentUser.id_user)
                    } else {
                        this.users = users
                    }
                    this.admins = users
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        changeSelectedAdmin(e) {
            if (!e.includes(this.getUser.id_user)){
                this.selectAdmin.push(this.getUser.id_user)
                this.$toast.error('O criador do relatório deve ser um Admin')
            }
        },

        async saveReport() {
            this.savingReport = true

            await this.saveDescription()

            this.filters.forEach((filter) => {
                filter.items = []
            })

            if (!this.selectedSlide){
                this.report.pivotTable = {
                    events: this.select,
                    option: this.option,
                    operation: this.operation,
                    // data: this.data,
                    renderer: this.rendererName,
                    aggregator: this.aggregatorName,
                    attributes: this.attributes,
                    // vals: [this.selectedVals[0], this.selectedVals[1]],
                    vals: this.vals,
                    headers: this.columns,
                    cols: this.cols,
                    rows: this.rows,
                    filters: this.valueFilter,
                    filterColumn: this.filters,
                    showTotalRow: this.showTotalRow,
                    showTotalCol: this.showTotalCol
                }
                this.report.pivotTable = JSON.stringify(this.report.pivotTable)
                try {
                    const payload = {
                            slide_sequence: this.report.number,
                            slide_title: this.report.title,
                            slide_text: this.report.text,
                            slide_css: this.report.format,
                            pivot_params_json: this.report.pivotTable,
                            id_dynamic_report: this.report.id
                    }

                    await this.saveUserGroup(1)
                    if (this.selectUser.length > 0) {
                        await this.saveUserGroup(0)
                    }

                    let res = await this.$http.post(
                        this.endpointSaveReport[0] + this.endpointSaveReport[1] + "/create",
                        payload
                    )
                    if (res) {
                        this.selectedSlide = res.data.id[0]
                        this.getSlides(this.report.id).then(() => {
                            this.setSlide()
                        })
                        this.closeSaveDialog()
                        this.$toast.success(res.data.msg)
                    }
                } catch (err) {
                    this.closeSaveDialog()
                    this.$fnError(err)
                }
            } else {
                this.editSlide()
            }
        },

        async editSlide () {
            this.report.pivotTable = {
                events: this.select,
                option: this.option,
                operation: this.operation,
                // data: this.data,
                renderer: this.rendererName,
                aggregator: this.aggregatorName,
                attributes: this.attributes,
                vals: this.vals,
                headers: this.columns,
                cols: this.cols,
                rows: this.rows,
                filters: this.valueFilter,
                filterColumn: this.filters,
                showTotalRow: this.showTotalRow,
                showTotalCol: this.showTotalCol
            }
            this.report.pivotTable = JSON.stringify(this.report.pivotTable)
            try {
                
                this.editUserGroup()

                const payload = {
                    slide_sequence: this.report.number,
                    slide_title: this.report.title,
                    slide_text: this.report.text,
                    slide_css: this.report.format,
                    pivot_params_json: this.report.pivotTable,
                    id_dynamic_report: this.report.id
                }
                
                let res = await this.$http.put(
                    this.endpointSaveReport[0] + this.endpointSaveReport[1] + "/edit/" + this.selectedSlide,
                    payload
                )
                if (res) {
                    this.selectedSlide = res.data.id[0]
                    this.getSlides(this.report.id).then(() => {
                        this.setSlide()
                    })
                }
                this.saveDialog = false
                this.savingReport = false
                this.newDialog = false
                // this.getData()
                this.step = "1"
            } catch (err) {
                this.saveDialog = false
                this.savingReport = false
                this.newDialog = false
                this.step = "1"
                this.$fnError(err)
            }
        },

        async listUserGroup (report) {
            
            this.selectAdmin = []
            this.selectUser = []

            const payload = {
                filter: {
                    conditions:[
                        {
                            AndOr: "AND",
                            column: "id_dynamic_report",
                            operator: "=",
                            value: report,
                        }
                    ]
                }
            }

            try {
                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + "dynamic-report-user-group" + "/list-user",
                    payload
                )
    
                if (res) {
                    let users = res.data.rows
                    users.forEach((user) => {
                        if (user.admin === 1) {
                            this.selectAdmin.push(user)
                            this.adminUserGroup = user.id_group
                        } else {
                            this.selectUser.push(user)
                            this.userGroup = user.id_group
                        }
                    })
                    this.selectAdminUnchanged = this.selectAdmin
                    this.selectUserUnchanged = this.selectUser
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async editUserGroup () {
            let toCreate = []
            let toDelete = []
            let admins = []
            let users = []
            this.selectAdmin.forEach((admin) => {
                if (typeof admin == 'number') {
                    admins.push({ id_user: admin })
                }
            })
            this.selectUser.forEach((user) => {
                if (typeof user == 'number') {
                    users.push({ id_user: user })
                }
            })
            typeof this.selectAdmin[0] == 'number' ? this.selectAdmin = admins : false
            typeof this.selectUser[0] == 'number' ? this.selectUser = users : false
            //TENTAR USAR O FOR
            //Array select: array de IDs - Array Unchanged: array de objetos
            //preencher o toCreate
            for (var cont = 0; cont < this.selectAdmin.length; cont++) {
                let index = this.selectAdminUnchanged.findIndex((val) => {
                    val.id_user  === this.selectAdmin[cont].id_user
                })
                if (index < 0) {
                    toCreate.push({
                        id_user: this.selectAdmin[cont].id_user,
                        id_group: this.adminUserGroup
                    })
                }
            }
            if (this.userGroup) {
                for (cont = 0; cont < this.selectUser.length; cont++) {
                    let index = this.selectUserUnchanged.findIndex((val) => {
                        val.id_user  === this.selectUser[cont].id_user
                    })
                    if (index < 0) {
                        toCreate.push({
                            id_user: this.selectUser[cont].id_user,
                            id_group: this.userGroup
                        })
                    }
                }
            } else {
                let users = []
                this.selectUser.forEach((user) => {
                    users.push(user.id_user)
                })
                this.selectUser = users
                this.selectUser.length > 0 ? await this.saveUserGroup(0) : false
            }
            //preencher o toDelete
            for (cont = 0; cont < this.selectAdminUnchanged.length; cont++) {
                let index = this.selectAdmin.findIndex((val) => {
                    val.id_user === this.selectAdminUnchanged[cont].id_user
                })
                if (index < 0) {
                    toDelete.push({
                        id_user_group: this.selectAdminUnchanged[cont].id_user_group,
                    })
                }
            }
            for (cont = 0; cont < this.selectUserUnchanged.length; cont++) {
                let index = this.selectUser.findIndex((val) => {
                    val.id_user === this.selectUserUnchanged[cont].id_user
                })
                if (index < 0) {
                    toDelete.push({
                        id_user_group: this.selectUserUnchanged[cont].id_user_group,
                    })
                }
            }

            const payload = {
                create: {
                    items: toCreate
                },
                delete: {
                    items: toDelete
                }
            }

            try {
                if (toCreate.length > 0 || toDelete.length > 0) {
                    await this.$http.post(
                        this.endpointSecurity[0] + this.endpointSecurity[1] + "/mass-effect",
                        payload
                    )
                }
            } catch (err) {
                this.savingReport = false
                this.$fnError(err)
            }
        },

        async newSlide () {

            this.report.pivotTable = {
                events: this.select,
                option: this.option,
                operation: this.operation,
                // data: this.data,
                renderer: this.rendererName,
                aggregator: this.aggregatorName,
                attributes: this.attributes,
                vals: this.vals,
                headers: this.columns,
                cols: this.cols,
                rows: this.rows,
                filters: this.valueFilter,
                filterColumn: this.filters,
                showTotalRow: this.showTotalRow,
                showTotalCol: this.showTotalCol
            }
            this.report.pivotTable = JSON.stringify(this.report.pivotTable)

            try {
                const payload = {
                    slide_sequence: this.reportNewSlide.number,
                    slide_title: this.reportNewSlide.title,
                    slide_text: this.reportNewSlide.text,
                    slide_css: this.reportNewSlide.format,
                    pivot_params_json: this.report.pivotTable,
                    id_dynamic_report: this.report.id
                }

                let res = await this.$http.post(
                    this.endpointSaveReport[0] + this.endpointSaveReport[1] + "/create",
                    payload
                )
                if (res) {
                    this.selectedSlide = res.data.id[0]
                    this.getSlides(this.report.id).then(() => {
                        this.setSlide()
                    })
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        // async getUsers () {
        //     try {
        //         const payload = {
        //             filter: {
        //                 page: 0,
        //                 tableRows: 10000,
        //                 conditions: [
        //                     {
        //                         AndOr: "AND",
        //                         column: "id_user",
        //                         operator: ">=",
        //                         value: 1
        //                     }
        //                 ]
        //             }
        //         }

        //         let res = await this.$http.post(
        //             this.endpointUser[0] + this.endpointUser[1] + "/list",
        //             payload
        //         )
        //         if (res) {
        //             this.users = res.data.rows
        //         }
        //     } catch (err) {
        //         this.$fnError(err)
        //     }
        // },

        async saveUserGroup (option) {
            try {
                let group =  []
                let groupFormatted = []
                option === 1 ? group = this.selectAdmin : group = this.selectUser
                group.forEach((user) => {
                    groupFormatted.push({
                        id_user: user
                    })
                })
                const payload = {
                    id_dynamic_report: this.report.id,
                    admin: option,
                    users: groupFormatted
                }

                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report-user-group' + "/create-report-group",
                    payload
                )
                if (res) {
                    this.users = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getReports () {
            try {
                const payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_module",
                                operator: "=",
                                value: 7
                            },
                            {
                                AndOr: "AND",
                                column: "report_type",
                                operator: "=",
                                value: 0
                            }
                        ]
                    }
                }

                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report' + "/list",
                    payload
                )
                if (res) {
                    this.reports = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getSlides (report) {
            this.slides = []
            try {
                const payload = {
                    filter: {
                        page: 0,
                        tableRows: 10000,
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_dynamic_report",
                                operator: "=",
                                value: report
                            }
                        ]
                    }
                }

                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report-slide' + "/list",
                    payload
                )
                if (res) {
                    this.slides = res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async setSlide () {
            this.reports.forEach((item) => {
                item.id_dynamic_report == this.selectedReport ? this.reportOpen = item : false
            })
            this.slides.forEach((slide) => {
               if (typeof slide.pivot_params_json === "string") {
                    slide.pivot_params_json = JSON.parse(slide.pivot_params_json)
                }
                slide.id_dynamic_report_slide == this.selectedSlide ? this.slide = slide : false
            })

            this.descriptionCompare = this.slide.dynamic_report
            this.report = {
                id: this.selectedReport,
                description: this.slide.dynamic_report,
                number: this.slide.slide_sequence,
                title: this.slide.slide_title,
                text: this.slide.slide_text,
                format: this.slide.slide_css,
                pivotTable: this.slide.pivot_params_json
            }

            this.tableName = this.slide.slide_title
            this.tableDescription = this.slide.dynamic_report
            this.select = this.slide.pivot_params_json.events
            this.items.forEach((item) => {
                if (item.id === this.select[0]) {
                    this.id_account_group_version = item.id_account_group_version
                }
            })
            this.columns =  this.slide.pivot_params_json.headers
            this.filters = this.slide.pivot_params_json.filterColumn
            this.rendererName = this.slide.pivot_params_json.renderer
            this.aggregatorName = this.slide.pivot_params_json.aggregator
            this.cols = this.slide.pivot_params_json.cols
            this.rows = this.slide.pivot_params_json.rows
            this.option = this.slide.pivot_params_json.option
            this.operation =  this.slide.pivot_params_json.operation
            this.vals =  this.slide.pivot_params_json.vals
            this.showTotalRow = this.slide.pivot_params_json.showTotalRow
            this.showTotalCol = this.slide.pivot_params_json.showTotalCol

            this.filters.forEach((filter) => {
                if (filter.selectedFilters.length > 0) {
                    this.showFilterItems(filter)
                    this.filterIsLoading(filter)
                }
            })
            await this.getData()
            this.$root.$emit('setValueFilter', this.slide.pivot_params_json.filters)

            await this.listUserGroup(this.selectedReport)

            const adminArray  = this.selectAdmin.find((admin) => admin.id_user === this.getUser.id_user)
            adminArray ? this.disableSave = false : this.disableSave = true
        },

        async copySlide (report) {
            try {
                const payload = {
                    id_dynamic_report_slide: this.selectedSlide,
                    id_dynamic_report: report
                }

                const res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report-slide' + "/copy-to-report",
                    payload
                )
                if (res) {
                    this.$toast.success(res.data.msg)
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async loadSlideToEdit () {
            this.selectedReport = this.$route.query.report
            this.selectedSlide = this.$route.query.slide
            await this.getUsers()
            await this.listUserGroup(this.$route.query.report)
            await this.getReports()
            await this.getSlides(this.$route.query.report)
            await this.setSlide()
            const adminArray  = this.selectAdmin.find((admin) => admin.id_user === this.getUser.id_user)
            adminArray ? this.saveDialog = true : this.disableSave = true
        },

        async exportToExcel () {
            this.loadingExport = true
            let conditions = []
            this.filters.forEach((column) => {
                if (column.selectedFilters.length > 0) {
                    let condition = {
                        AndOr: "AND",
                        column: column.columnId,
                        operator: "IN",
                        value: column.selectedFilters
                    }
                    conditions.push(condition)
                }
            })
            try {
                const payload = {
                    id_account_group_version: this.id_account_group_version,
                    events: [...this.select],
                    NoFormulas: true,
                    columns: this.columns,
                    calculateDifference: false,
                    ResultInArray: true,
                    queryType: this.option,
                    filter: {
                        conditions: conditions
                    }
                }

                let res = await this.$http.post(
                    this.endpoint[0] + this.endpoint[1] + "/report-analyse",
                    payload
                )
                if (res) {
                    this.loadingExport = false
                    window.open(res.data.token)
                }
            } catch (err) {
                this.loadingExport = false
                this.$fnError(err)
            }
        },

        //tab refactor

        async showFilterItems(filter) {
            if (filter.items.length < 1) {
                this.loadingFilters.push(filter.name)

                try {
                    let res = await this.$http.post(filter.server + '/list-options', { filter: { conditions: filter.conditions } })
                    // let res = await this.$http.post(filter.local + '/list-options', {})
                    if (res) {
                        let foundFilter = this.filters.find(f => f.columnId === filter.columnId)
                        foundFilter.items = res.data.rows

                        this.loadingFilters.pop()
                    }
                } catch (err) {
                    this.loadingFilters.pop()
                    this.$fnError(err)
                }
            }
        },

        filterIsLoading(filter) {
            if (this.loadingFilters.includes(filter.name)) {
                return true
            } else {
                return false
            }
        },
    },

    watch: {
        select (val) {
            let event = {}
            this.items.forEach((item) => {
                if (item.id === val[0]) {
                    event = item
                    this.id_account_group_version = item.id_account_group_version
                }
            })
            this.filters.forEach((filter) => {
                if (filter.conditions) {
                    filter.conditions.forEach((condition) => {
                        if (event[condition.column]) {
                            condition.value = event[condition.column]
                        }
                    })
                }
            })
            this.showFilterItems(this.filters.find(filter => filter.columnId == "id_account_group")).then(() => {
                let filter = this.filters.find(filter => filter.columnId == "id_account_group")
                let filteredItems = filter.items.sort((a, b) => {
                    return (a.account_order > b.account_order) ? 1 : ((b.account_order > a.account_order) ? -1 : 0);
                })
                let accountTexts = []
                filteredItems.forEach((filter => {
                    accountTexts.push(filter.text)
                }))
                this.accountGroups = accountTexts
            })
        },

        //tab refactor test
        columns (val) {
            if (!this.report.id){
                this.filters = []
                this.selects = []
                val.forEach((column) => {
                    if (column.filter) {
                        column.items = []
                        column.selectedFilters = []
                        this.filters.push(column)
                    }
                })
                val.forEach((column) => {
                    if (column.select) {
                        this.selects.push(column)
                    }
                })
            }
        },

        //CAUSANDO ERRO AO RESETAR VUE, FILTEREDDATA NÃO USADO EM OUTRO LUGAR
        // config: {
        //     // eslint-disable-next-line no-unused-vars
        //     handler(value, oldValue) {
        //         const PivotData = PivotUtilities.PivotData
        //         this.data = this.dataRows
        //         this.filteredData = new PivotData(value).getFilteredData()
        //     },
        //     deep: true,
        //     immediate: false,
        // },

        async option (value) {
            // if (value === 'BI_YEAR') {
            //     this.columns = this.columnsBiYear
            //     this.vals = ["Total Ano Ano 1"]
            if (value === 'YEAR') {
                this.columns = this.columnsYear
                this.vals = ["Total Ano"]
            } else {
                this.columns = this.columnsYearMonth
                this.vals = ["Valor"]
            }
            // await this.getHeaders()
            // await this.getData()
        },

        operation (value) {
            if (value == "- (Diferença)") {
                this.operator = "-"
            } else if (value == "% (Percentual)") {
                this.operator = "%"
            } else {
                this.operator = null
            }
            // this.getData()
        },

        step (value) {
            if (value === 3) {
                this.report.id ? this.listUserGroup(this.report.id) : false
            }
        },

        page (value) {
            this.tableName = this.slides[value].slide_title
            this.tableDescription = this.slides[value].dynamic_report
            this.select = this.slides[value].pivot_params_json.events
            this.columns =  this.slides[value].pivot_params_json.headers
            this.filters = this.slides[value].pivot_params_json.filterColumn
            this.rendererName = this.slides[value].pivot_params_json.renderer
            this.aggregatorName = this.slides[value].pivot_params_json.aggregator
            this.cols = this.slides[value].pivot_params_json.cols
            this.rows = this.slides[value].pivot_params_json.rows
            this.option = this.slides[value].pivot_params_json.option
            this.operation =  this.slides[value].pivot_params_json.operation
        },
        
        report: {
            deep: true,
            async handler(value) {
                let payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "dynamic_report",
                                operator: "=",
                                value: `${value.description}`
                            }
                        ]
                    }
                }

                if (!this.report.id) {
                    try {
                        const res = await this.$http.post(this.$ipDynamicReport + 'dynamic-report/list-options', payload)
                        if (res) {
                            if (res.data.rows.length > 0) {
                                return this.verifiedReportDescription = -1
                            } else {
                                return this.verifiedReportDescription = 1
                            }
                        }
                    } catch (err) {
                        this.$fnError(err)
                    }
                } else {
                    return this.verifiedReportDescription = 1
                }
            }
        },
    },
}
</script>

<style src="@/../public/styles/pivottable/pivottable.full.css"/>